.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: all;
  opacity: 0;
  pointer-events: none;
}
.overlay.show {
  pointer-events: all;
  z-index: 50;
  opacity: 1;
}

.modal {
  z-index: 450;
  position: absolute;
  right: -480px;
  top: 0;
  opacity: 1;
  transition: ease all 0.55s;
  pointer-events: none;
}
.modal.show {
  pointer-events: all;
  right: 0;
}

.modal {
  width: 480px;
  max-width: 100vw;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;

  .dashboard {
    .card-wrapper {
      display: block;
    }
  }

  .dashboard .card-wrapper .ant-card .ant-card-body {
    padding: 20px 60px 60px;
    background-color: $wht;
  }

  .ant-card {
    width: 100% !important;
    height: 100%;
    box-sizing: content-box;
    box-shadow: none;
    //padding: 60px;
    //padding-bottom: 80px;
    border-radius: 0;

    .ant-card-head {
      background: transparent;
      border-bottom: 0;
      padding: 60px 60px 0;

      .ant-card-head-title {
        @include heading($heading-font-size, 41px);
        text-transform: none;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    .ant-card-body {
      box-sizing: border-box;
      .ant-form-item:last-child {
        margin-bottom: 0;
      }
      .ant-form-item-control-input-content {
        margin: 0 !important;
        .ant-select-multiple .ant-select-selection-placeholder {
          right: unset;
        }
      }
      .ant-input {
        border-radius: 4px;
      }
      .ant-btn-primary {
        
        text-transform: none;
      }
      .ant-btn-secondary {
        border: none;
        box-shadow: none;
        margin-right: 3px;
      }
    }
  }
}
.modal::-webkit-scrollbar {
  display: none;
}

.deleteModal {
  .ant-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .ant-btn {
      width: 180px;
      // border: 1px solid #1138c2;
      // border-radius: 4px;
    }
    .ant-btn-primary {
      border: none;
    }
    .ant-btn:hover {
      // color: inherit;
    }
    .ant-btn-primary:hover {
      color: #fff;
    }
  }
}
.delete-user {
  position: absolute;
  right: 5%;
  top: 1%;
  cursor: pointer;
}

.x-btn {
  position: absolute;
  top: 70px;
  right: 60px;
}

//delete popconfirm
.ant-popover-buttons {
  width: 210px;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.ant-btn.ant-btn-sm {
  width: 100px;
  margin: 0;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
  height: 37px;
  // border: 2px solid #3372ed;
  // background: linear-gradient(180deg, #3372ed, #1138c2);
  background: #a1b8d0;
}

.ant-btn.ant-btn-primary.ant-btn-sm:hover {
  opacity: 0.8;
  background: #a1b8d0;
  border: 2px solid #a1b8d0;
  color: #fff;
}

//razmak paginacija i div dole
.ant-table-pagination.ant-pagination {
  margin-bottom: 15px !important;
}

.table {
  margin-bottom: 30px;
}
