.actions-block {
  padding: 0 15px 5px;
  // margin-bottom: 20px;
  //margin-bottom: 8px;
  background-color: $background-color;
  // border-radius: 2px;
}

.users-table {
  .actions-block {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 0;
}

.columnCompany {
  // .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  //   border-color: darkgreen;
  //   border-right-width: 1px;
  // }

  // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   box-shadow: 0 0 0 3px rgba(156, 178, 66, 0.2);
  // }

  .ant-select-clear {
    top: 55% !important;
    right: 15% !important;
    width: 7%;
    background: white !important;

    span {
      color: red !important;
      font-size: 18px;
    }
  }
}