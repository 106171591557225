button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.log-out-btn {
  @include btn(transparent, #337aed);
  width: 100%;
}

.ant-btn {
  line-height: normal;
  color: #fff;
  // background: linear-gradient(180deg, #3372ed, #1138c2);
  // background: linear-gradient(180deg, #d9cdc4, #bda18c);
  background: #a1b8d0;
  border: #a1b8d0;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
    background: #a1b8d0;
  }

  &:active,
  &:focus {
    // background: linear-gradient(180deg, #2768e9, #0b32c0f6);
    background: #a1b8d0;
    color: #fff;
  }
}

button {
  &.ant-btn {
    &.ant-btn-icon-only {
      background: transparent;
      color: #000;
    }
  }
}
.ant-btn[disabled],
.ant-btn[disabled]:hover {
  background: transparent;
}
.ant-input:hover {
  border-color: #7999bc;
}

.ant-input:focus {
  border: 1px solid #7999bc;
  box-shadow: 2px 2px 2px rgba(121, 153, 188, 0.3);
}


.b2b-primary {
    position: relative;
    display: inline-block;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
    // user-select: none;
    padding: 4px 15px;
    height: 38px;
    letter-spacing: 0.05em;
    font-size: 13px;
    transition: none;
    color: #fff;
    background: #a1b8d0;
    border:2px solid #a1b8d0;
    border-radius: 5px;
    line-height: normal;
    cursor:pointer;
}
.b2b-primary:hover {
  opacity: 0.8;
}
.b2b-primary:active {
  opacity: 0.8;
}

.b2b-secondary {
    position: relative;
    display: inline-block;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
    // user-select: none;
    padding: 4px 15px;
    height: 38px;
    letter-spacing: 0.05em;
    font-size: 13px;
    transition: none;
    background: #fff;
    color: #a1b8d0;
    border:2px solid #a1b8d0;
    border-radius: 5px;
    line-height: normal;
    cursor:pointer;
}
.b2b-secondary:hover {
  opacity: 0.8;
  background: #fff;
  color: #a1b8d0;
}
.b2b-secondary:active {
  opacity: 0.8;
}